.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.footer {
  position: relative;
}

.global_navigation {
  position: fixed !important;
  bottom: 30px !important;
  right: 30px !important;
  border-radius: 20px !important;
}
.modal_container{
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.overlay {
  height: 100%;
  width: 0;
  transform: scaleY(0);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #00000073;
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay-main-menu {
  height: 100%;
  width: 0;
  transform: scaleY(0);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgb(20, 72, 129);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content-main-menu {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.overlay-content{
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.card_style {
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  color: #ddd;
  top: 45px;
  right: 45px;
  font-size: 40px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 768px) {
  .global_navigation {
    right: 5px;
  }
}

.contant-layout{
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: rgb(20, 72, 129);

 
}
.app-header.ant-layout-header{
  background-color:#00477e !important
}
.top-nav.light .ant-menu{
  color:#383a3c !important
}