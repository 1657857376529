.global_navigation {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .global_navigation {
    right: 5px;
  }
}
.ant-layout.app-layout {
  background-color: #0000000d;
}
thead.ant-table-thead {
  background: #beccd73d;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(20, 72, 129) !important;
}
.ant-tabs-ink-bar {
  background: rgb(20, 72, 129);
}

.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  background: #1448810d;
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):hover {
  background-color: rgb(66 29 129 / 5%);
  border-color: rgb(66 29 129 / 5%);
  color: rgb(20, 72, 129);
}
.ant-btn {
  color: rgb(20, 72, 129) !important;

  background-color: #2a59d105 !important;
  border-color: rgb(66 29 129 / 5%) !important;
}
.ant-card .ant-card-bordered {
  box-shadow: 0px 0px 36px #e4e4ec;
}
.ant-tag .ant-tag-has-color {
  color: rgb(66 29 129) !important;
}

.ant-tabs-ink-bar {
  background: #144881 !important;
}

.flex {
  display: flex;
  align-items: center;
  background-color: #f5f3f9 !important;
  border: rgb(66 29 129 / 5%) !important;
}
.week-button {
  display: none !important;
}
.box {
  background-color: #f5f3f9 !important;
}
.highlighted {
  color: #fff;
  background-color: #60448f !important;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon {
  color: black !important;
}
.btn-weekpicker button {
  padding: 8.5px 1.25rem !important;
  border-radius: 0.625rem !important;
}
.outline {
  margin: 0 13px !important;
}
.dark {
  color: #144881 !important;
}
.ant-picker-content tr th[aria-label="empty cell"] {
  display: none;
}
.ant-picker-week-panel-row td.ant-picker-cell-week {
  display: none;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #e3ddec !important;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #455560 !important;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #144881 !important;
}

.ant-comment-content-author-name > :hover,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #144881 !important;
}
.ant-select-selection-overflow-item {
  width: 100% !important;
}
.ant-select-multiple .ant-select-selection-item {
  justify-content: space-between !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.lab label {
  margin-left: 10px !important;
}
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-active,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-open,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open,
.top-nav.light .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: rgb(0 70 123);
}
.ant-menu-horizontal {
  border-bottom-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* css for auto login loader */

.global_loader {
  position: relative;
}

.global_loader .ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 300px;
}

.global_loader .ant-spin-nested-loading > div > .ant-spin {
  height: 0 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  background: transparent !important;
}
